import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/about/meet-the-team",
    name: "Meet The Team",
    component: () =>
      import(
        /* webpackChunkName: "meet-the-team" */ "../views/MeetTheTeam.vue"
      ),
  },
  {
    path: "/about/meet-the-team/gemma",
    name: "Gemma",
    component: () =>
      import(/* webpackChunkName: "gemma" */ "../views/team/Gemma.vue"),
  },
  {
    path: "/about/meet-the-team/diane",
    name: "Diane",
    component: () =>
      import(/* webpackChunkName: "diane" */ "../views/team/Diane.vue"),
  },
  {
    path: "/about/meet-the-team/heather",
    name: "Heather",
    component: () =>
      import(/* webpackChunkName: "heather" */ "../views/team/Heather.vue"),
  },
  {
    path: "/about/meet-the-team/alahna",
    name: "Alahna",
    component: () =>
      import(/* webpackChunkName: "alahna" */ "../views/team/Alahna.vue"),
  },
  {
    path: "/about/meet-the-team/adrian",
    name: "Adrian",
    component: () =>
      import(/* webpackChunkName: "adrian" */ "../views/team/Adrian.vue"),
  },
  {
    path: "/about/meet-the-team/claire",
    name: "Claire",
    component: () =>
      import(/* webpackChunkName: "claire" */ "../views/team/Claire.vue"),
  },
  {
    path: "/about/meet-the-team/dorota",
    name: "Dorota",
    component: () =>
      import(/* webpackChunkName: "dorota" */ "../views/team/Dorota.vue"),
  },
  {
    path: "/about/our-commitment-to-you",
    name: "Our Commitment To You",
    component: () =>
      import(
        /* webpackChunkName: "our-commitment-to-you" */ "../views/OurCommitment.vue"
      ),
  },
  {
    path: "/about/areas-covered",
    name: "Areas Covered",
    component: () =>
      import(
        /* webpackChunkName: "areas-covered" */ "../views/AreasCovered.vue"
      ),
  },
  {
    path: "/services",
    name: "Services",
    component: () =>
      import(/* webpackChunkName: "services" */ "../views/Services.vue"),
  },
  {
    path: "/treatments",
    name: "Treatments",
    component: () =>
      import(/* webpackChunkName: "treatments" */ "../views/Treatments.vue"),
  },
  {
    path: "/prices",
    name: "Prices",
    component: () =>
      import(/* webpackChunkName: "prices" */ "../views/Prices.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/faqs",
    name: "Faqs",
    component: () => import(/* webpackChunkName: "faqs" */ "../views/Faqs.vue"),
  },
  {
    path: "/testimonials",
    name: "Testimonials",
    component: () =>
      import(
        /* webpackChunkName: "testimonials" */ "../views/Testimonials.vue"
      ),
  },
  {
    path: "/privacy",
    name: "Privacy Policy",
    component: () =>
      import(
        /* webpackChunkName: "privacy" */ "../views/PrivacyPolicy.vue"
      ),
  },
  {
    path: "/:pathMatch(.*)",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // https://vueschool.io/lessons/how-to-control-the-scroll-behavior-of-vue-router
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      const position = {};
      if (to.hash) {
        position.selector = to.hash;
        if (document.querySelector(to.hash)) {
          return position;
        }
        return false;
      } else {
        return { top: 0 };
      }
    }
  },
});

router.beforeEach((to, from, next) => {
  document.title = `${process.env.VUE_APP_TITLE} - ${to.name}`;
  next();
});

export default router;
