<template>
  <button
    class="
      absolute
      top-0
      right-0
      block
      z-50
      m-6
      mt-10
      flex
      lg:hidden
      lg:pointer-events-none
      cursor-pointer
    "
    :class="{ 'menu-open': isOpen }"
    @click="toggleMenu()"
  >
    <span class="bg-royalblue-900"></span>
    <span class="bg-royalblue-900"></span>
    <span class="bg-royalblue-900"></span>
  </button>
  <nav
    v-if="isDesktop"
    class="flex flex-row lg:mt-0 md:ml-auto lg:mt-3 lg:pr-0 lg:pb-0 lg:relative px-6"
    :class="{ 'menu-open': isOpen }"
  >
    <ul
      class="
        flex flex-row flex-wrap
        items-baseline
        lg:items-center
        lg:px-0
        lg:space-x-4
        xl:space-x-6
        lg:space-y-0
        mb-3
      "
    >
      <li>
        <router-link
          to="/"
          class="lg:text-base font-medium text-royalblue-900"
          @click="toggleMenu()"
          exact
          >Home
        </router-link>
      </li>

      <li
        class="relative inline-block w-auto"
        @mouseover="isVisible = true"
        @mouseleave="isVisible = false"
      >
        <span
          class="
            inline-flex
            items-center
            justify-between
            pr-2
            transition-all
            duration-500
            w-auto
            cursor-pointer
          "
          :class="{ 'router-link-active': subIsActive('/about') }"
        >
          <span
            class="
              text-xl
              lg:text-base
              font-medium
              text-royalblue-900
              flex-shrink-0
            "
            >About
          </span>
          <svg
            viewBox="0 0 20 20"
            class="fill-current flex-shrink-0 w-7 h-7 lg:w-5 lg:h-5 ml-1"
          >
            <path
              :class="{ 'rotate-180': isVisible }"
              class="ease-in-out origin-center transform"
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </span>
        <transition
          enter-active-class="transition duration-300 ease-out transform"
          enter-class="-translate-y-3 scale-95 opacity-0"
          enter-to-class="translate-y-0 scale-100 opacity-100"
          leave-active-class="transition duration-150 ease-in transform"
          leave-class="translate-y-0 opacity-100"
          leave-to-class="-translate-y-3 opacity-0"
        >
          <div v-show="isVisible" class="lg:absolute lg:pt-2">
            <div
              class="
                relative
                lg:border lg:border-denim-50
                lg:rounded
                bg-white
                p-2
                lg:shadow-lg
              "
            >
              <div
                class="
                  block
                  absolute
                  top-0
                  w-4
                  h-4
                  origin-center
                  transform
                  rotate-45
                  translate-x-5
                  -translate-y-2
                  bg-white
                  border-t border-l border-denim-50
                  rounded-sm
                  pointer-events-none
                "
              ></div>
              <ul class="relative">
                <li>
                  <router-link
                    to="/about"
                    class="block w-full whitespace-nowrap"
                    @click="toggleMenu()"
                    >About us</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/about/meet-the-team"
                    class="block w-full whitespace-nowrap"
                    @click="toggleMenu()"
                    >Meet the team</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/about/our-commitment-to-you"
                    class="block w-full whitespace-nowrap"
                    @click="toggleMenu()"
                    >Our commitment to you</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/about/areas-covered"
                    class="block w-full whitespace-nowrap"
                    @click="toggleMenu()"
                    >Areas covered</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </transition>
      </li>
      <li>
        <router-link
          to="/services"
          class="lg:text-base font-medium text-royalblue-900"
          @click="toggleMenu()"
          >Services
        </router-link>
      </li>
      <li>
        <router-link
          to="/treatments"
          class="lg:text-base font-medium text-royalblue-900"
          @click="toggleMenu()"
          >Treatments
        </router-link>
      </li>
      <li>
        <router-link
          to="/prices"
          class="lg:text-base font-medium text-royalblue-900"
          @click="toggleMenu()"
          >Prices
        </router-link>
      </li>
      <li>
        <router-link
          to="/contact"
          class="lg:text-base font-medium text-royalblue-900"
          @click="toggleMenu()"
          >Contact
        </router-link>
      </li>
    </ul>
  </nav>
  <nav
    v-if="!isDesktop"
    class="flex flex-col mt-4 bg-white z-40 px-6 pointer-events-none"
    :class="{ 'menu-open pointer-events-auto': isOpen }"
  >
    <ul class="flex flex-col flex-wrap items-baseline space-y-2 mb-3">
      <li>
        <router-link
          to="/"
          class="text-xl font-medium text-royalblue-900"
          @click="toggleMenu()"
          exact
          >Home
        </router-link>
      </li>

      <li class="relative inline-block w-full" @click="isVisible = !isVisible">
        <span
          class="
            inline-flex
            items-center
            justify-between
            pr-2
            transition-all
            duration-500
            w-full
          "
          :class="{ 'router-link-active': subIsActive('/about') }"
        >
          <span class="text-xl font-medium text-royalblue-900 flex-shrink-0"
            >About</span
          >
          <svg
            viewBox="0 0 20 20"
            class="fill-current flex-shrink-0 w-7 h-7 ml-1"
          >
            <path
              :class="{ 'rotate-180': isVisible }"
              class="ease-in-out origin-center transform"
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </span>
        <transition
          enter-active-class="transition duration-300 ease-out transform"
          enter-class="-translate-y-3 scale-95 opacity-0"
          enter-to-class="translate-y-0 scale-100 opacity-100"
          leave-active-class="transition duration-150 ease-in transform"
          leave-class="translate-y-0 opacity-100"
          leave-to-class="-translate-y-3 opacity-0"
        >
          <div v-show="isVisible">
            <div class="relative bg-white p-2">
              <div
                class="
                  hidden
                  absolute
                  top-0
                  w-4
                  h-4
                  origin-center
                  transform
                  rotate-45
                  translate-x-5
                  -translate-y-2
                  bg-white
                  border-t border-l border-denim-50
                  rounded-sm
                  pointer-events-none
                "
              ></div>
              <ul class="relative">
                <li>
                  <router-link
                    to="/about"
                    class="block w-full whitespace-nowrap"
                    @click="toggleMenu()"
                    >About us</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/about/meet-the-team"
                    class="block w-full whitespace-nowrap"
                    @click="toggleMenu()"
                    >Meet the team</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/about/our-commitment-to-you"
                    class="block w-full whitespace-nowrap"
                    @click="toggleMenu()"
                    >Our commitment to you</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/about/areas-covered"
                    class="block w-full whitespace-nowrap"
                    @click="toggleMenu()"
                    >Areas covered</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </transition>
      </li>
      <li>
        <router-link
          to="/services"
          class="text-xl font-medium text-royalblue-900"
          @click="toggleMenu()"
          >Services
        </router-link>
      </li>
      <li>
        <router-link
          to="/treatments"
          class="text-xl font-medium text-royalblue-900"
          @click="toggleMenu()"
          >Treatments
        </router-link>
      </li>
      <li>
        <router-link
          to="/prices"
          class="text-xl font-medium text-royalblue-900"
          @click="toggleMenu()"
          >Prices
        </router-link>
      </li>
      <li>
        <router-link
          to="/contact"
          class="text-xl font-medium text-royalblue-900"
          @click="toggleMenu()"
          >Contact
        </router-link>
      </li>
    </ul>
    <div class="flex flex-row mb-14 space-x-6">
      <a
        href="https://www.facebook.com/balanceneurophysio"
        class="text-5xl text-royalblue-400 m-3"
        target="_blank"
      >
        <font-awesome-icon :icon="['fab', 'facebook-square']" />
      </a>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
button {
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 1.8125rem;
  height: 1.25rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0px;
  z-index: 10;

  &:focus {
    outline: none;
  }

  span {
    width: 1.8125rem;
    height: 0.125rem;
    background: rgb(0, 0, 0);
    border-radius: 10px;
    transition: all 0.15s linear 0s;
    position: relative;
    transform-origin: 0.3125rem center;

    &:first-child {
      transform: rotate(0deg);
    }

    &:nth-child(2) {
      opacity: 1;
      width: 1.8125rem;
    }

    &:nth-child(3) {
      transform: rotate(0deg);
    }
  }

  &.menu-open span {
    &:first-child {
      transform: rotate(45deg);
    }

    &:nth-child(2) {
      opacity: 0;
      width: 0;
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
    }
  }
}

@media (max-width: 1024px) {
  nav {
    width: 100%;
    height: 100vh;
    overflow: auto;
    transition: all 0.3s ease-in-out 0s;
    position: fixed;
    opacity: 0;
    // transform: translateY(100%);
    z-index: 9999;
    top: 104px;
    left: 0;
    overflow: scroll;

    ul li a {
      display: inline-flex;
    }

    &.menu-open {
      opacity: 1;
      // transform: translateX(0px);
    }
  }
}

nav ul li {
  @apply transition-all duration-300;
}

nav ul li a,
nav ul li > span {
  padding: 10px 12px;
}

nav ul li a,
nav ul li span,
nav ul li a span,
nav ul li svg path {
  @apply transition-all duration-300;
}

nav ul > li a:hover,
nav ul > li a:hover span,
nav ul ul > li a:hover,
nav ul > li:hover span,
nav ul > li:hover span svg path,
.router-link-active span,
.router-link-active svg path,
.router-link-exact-active,
.router-link-exact-active > span {
  @apply text-primary-600;
}

nav ul li a a {
  @apply text-royalblue-900 font-normal;
}
</style>

<script>
export default {
  data () {
    return {
      isOpen: false,
      // headerMobileHeight: 104,
      isVisible: false,
      isDesktop: null,
      // windowWidth: 0,
      tabletDownMq: window.matchMedia('(max-width: 1024px)')
    }
  },
  computed: {
    breakpoint: function () {
      return this.tabletDownMq
    }
  },
  mounted () {
    this.handleMqBreakpoint()
    this.tabletDownMq.addEventListener('change', this.handleMqBreakpoint)
  },
  beforeUnmount () {
    window.removeEventListener('change', this.handleMqBreakpoint)
  },
  methods: {
    subIsActive (input) {
      const paths = Array.isArray(input) ? input : [input]
      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0 // current path starts with this path string
      })
    },
    handleMqBreakpoint: function () {
      this.$nextTick(() => {
        if (this.tabletDownMq.matches) {
          this.isDesktop = false
        } else {
          this.isDesktop = true
        }
      })
    },
    menuToggleMQ: function (x) {
      if (x.matches) {
        // If media query matches
        this.isOpen = !this.isOpen
        document.querySelector('html').classList.toggle('overflow-hidden')
      } else {
        document.querySelector('html').classList.remove('overflow-hidden')
      }
    },
    toggleMenu: function () {
      const x = this.breakpoint
      return this.menuToggleMQ(x)
    }
  }
}
</script>
