<template>
  <section class="container">
    <div class="flex flex-col items-center container__inner mb-10">
      <div class="mb-8">
        <a
          href="https://www.facebook.com/balanceneurophysio"
          class="text-5xl text-royalblue-400 mb-0 m-6"
          target="_blank"
        >
          <font-awesome-icon :icon="['fab', 'facebook-square']" />
        </a>
      </div>

      <p class="space-x-8 sm:space-x-14">
        <router-link class="text-royalblue-300" :to="{ name: 'Faqs' }"
          >FAQs</router-link
        >
        <router-link class="text-royalblue-300" :to="{ name: 'Testimonials' }"
          >Testimonials</router-link
        >
        <router-link class="text-royalblue-300" :to="{ name: 'Privacy Policy' }"
          >Privacy</router-link
        >
      </p>
    </div>
    <div
      class="
        container__inner
        flex flex-row
        items-center
        space-x-5
        md:space-x-10
      "
    >
      <div class="">
        <img
          alt="content"
          class="object-cover object-center"
          src="../assets/images/chartered-society-of-physiotherapy.jpg"
        />
      </div>
      <div class="">
        <img
          alt="content"
          class="object-cover object-center"
          src="../assets/images/hcpc-registered.jpg"
        />
      </div>
      <div class="">
        <img
          alt="content"
          class="object-cover object-center"
          src="../assets/images/acpin-logo.jpg"
        />
      </div>
      <div class="">
        <img
          alt="content"
          class="object-cover object-center"
          src="../assets/images/acpivr-logo.png"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {}
}
</script>
