<template>
  <div>
    <main class="flex flex-col min-h-screen mb-14">
      <div class="flex-1">
        <the-header></the-header>
        <section class="main-content">
          <router-view class="mb-14 sm:mb-28"></router-view>
        </section>
      </div>
      <the-footer></the-footer>
    </main>
  </div>
</template>

<script>
import TheHeader from "./components/TheHeader.vue";
import TheFooter from "./components/TheFooter.vue";

export default {
  components: {
    "the-header": TheHeader,
    "the-footer": TheFooter,
  },
};
</script>
