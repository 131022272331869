<template>
  <header>
    <div
      class="
        container
        relative
        max-w-none
        flex flex-wrap
        py-4
        flex-col
        md:flex-row
        lg:items-center
      "
    >
      <div class="flex font-medium items-center text-gray-900">
        <router-link to="/" class="flex items-center">
          <div class="logo">
            <img
              class="logo__image"
              src="../assets/images/balance_neuro_physio-logo.svg"
              width="64"
              height="70"
              alt="Balance Neuro Physio Logo"
            />
          </div>
          <span class="ml-3 font-medium">Balance Neuro Physio</span>
        </router-link>
      </div>
      <the-navigation></the-navigation>
    </div>
  </header>
</template>

<script>
import TheNavigation from "./TheNavigation.vue";

export default {
  components: {
    "the-navigation": TheNavigation,
  },
};
</script>
